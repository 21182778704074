import {Injectable} from '@angular/core'

export interface IDebugMessage {
  ts: number
  message: string
}

@Injectable({
  providedIn: 'root'
})
export class DebugService {
  private pMessages: IDebugMessage[] = []


  public messages(): IDebugMessage[] {
    return this.pMessages
  }

  public log(message: string): void {
    this.pMessages.push({ts: Date.now(), message})
  }

  constructor() {
  }
}
